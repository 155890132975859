class HttpService {
    async getItems(url, options) {
        let headers = {};
        let queryStr = "";
        if (options) {
            if (options.typeName) {
                url += '/' + options.typeName
            }
            if (options.status) {
                url += '/' + options.status
            }
            if (options.isCount !== false) {
                queryStr += `&$count=true`;
            }
            if (options.top !== undefined && options.top > 0) {
                let top = options.top;
                top = top < 0 ? 1 : top;
                queryStr += `&$top=${top}`;
            }
            if (options.skip !== undefined && options.skip >= 0) {
                let skip = options.skip;
                skip = skip < 0 ? 0 : skip;
                queryStr += `&$skip=${skip}`;
            }
            if (options.select) {
                if (queryStr.length === 0) {
                    queryStr += `$select=${options.select}`;
                } else {
                    queryStr += `&$select=${options.select}`;
                }
            }
            if (options.filter) {
                queryStr += `&$filter=${options.filter}`;
            }
            if (options.sort) {
                queryStr += `&$orderby=${options.sort}`;
            }

            if (options.page) {
                let page = options.page;
                page = page < 0 ? 1 : page;
                queryStr += `&page=${page}`;
            }
            if (options.pageSize) {
                let pageSize = options.pageSize;
                pageSize = pageSize < 0 ? 0 : pageSize;
                queryStr += `&pageSize=${pageSize}`;
            }
            if (options.type) {
                queryStr += `&type=${options.type}`;
            }
        }
        let baseUrl = `${url}`;
        if (queryStr) {
            baseUrl = `${url}?${queryStr}`;
        }
        let resp = await fetch(baseUrl, {headers: headers});
        return resp.json();
    }

    async getItem(url, id, options) {
        let headers = {};
        if (id) {
            url = url + "/" + id + "";
        }
        let queryStr = "";
        if (options) {
            if (options.status) {
                url += '/' + options.status
            }
            if (options.page) {
                let page = options.page;
                page = page < 0 ? 1 : page;
                queryStr += `&page=${page}`;
            }
            if (options.pageSize) {
                let pageSize = options.pageSize;
                pageSize = pageSize < 0 ? 0 : pageSize;
                queryStr += `&pageSize=${pageSize}`;
            }

        }
        let baseUrl = `${url}`;
        if (queryStr) {
            baseUrl = `${url}?${queryStr}`;
        }
        let resp = await fetch(baseUrl, {headers: headers});
        return resp.json();
    }

    async loadConfigs(url) {
        return await fetch(url);
    }

    async updateItem(url, id, item) {
        let headers = {};
        let baseUrl = `${url}`;
        if (id !== null && id !== undefined) {
            baseUrl = `${url}/${id}`;
        }
        return await fetch(url, {
            method: "patch",
            body: JSON.stringify(item),
            headers: headers
        });
    }

    async deleteItem(url, id) {
        let headers = {};
        let baseUrl = `${url}`;
        if (id !== null && id !== undefined) {
            baseUrl = `${url}/${id}`;
        }
        return await fetch(baseUrl, {
            method: "delete",
            headers: headers
        });
    }

    async createItem(url, params) {
        let headers = {};
        if (params !== null && params !== undefined) {
            return await fetch(url, {
                method: "post",
                body: JSON.stringify(params),
                headers: headers
            });
        }
        return await fetch(url, {
            method: "post",
            headers: headers
        });
    }

    async post(url, headers, params) {
        if (headers === null) {
            headers = {'Content-Type': 'application/json'};
        }
        let resp;
        if (params !== null && params !== undefined) {
            resp = await fetch(url, {
                method: "post",
                body: JSON.stringify(params),
                headers: headers
            });
        } else {
            resp = await fetch(url, {
                method: "post",
                headers: headers
            });
        }
        return resp.json();
    }

    async uploadFile(url, formData) {
        let headers = {};
        if (formData !== null && formData !== undefined) {
            return await fetch(url, {
                method: "post",
                body: formData,
                headers: headers
            });
        }
        return await fetch(url, {
            method: "post",
            headers: headers
        });
    }
}

export default new HttpService();