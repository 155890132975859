export const TYPE_SPECIAL_GROUP = {
    DEANERY: 'deanery'
}

export const ANNIVERSARIES = {
    BIRTHDAY: 'birth',
    CLERGY: 'linh_muc',
    DIACONUS: 'pho_te'
}

export const DOMAINS = {
    MAP_URL: 'https://maps.giaophanphucuong.org'
}

export const AUDIOS_DEFAULT = 'noi-voi-chinh-minh';
export const DEOCESE_DEFAULT = '35457ea2-8fcd-43b9-9535-22ef79f771da';


export const MASSESTIME = {
    AFTER_SAT: {
        code: 'chieu_thu_bay',
        name: 'Chiều thứ Bảy'
    },
    SUNDAY: {
        code: 'chua_nhat',
        name: 'Chúa Nhật'
    },
    NORMAL: {
        code: 'ngay_thuong',
        name: 'Ngày thường'
    }
}

export const NEWS_TYPE = {
    LOGOS: {
        link: 'loi-chua',
        code: 'loi-chua',
        name: 'Lời Chúa'
    },
    FOCUS: {
        link: 'tinTieuDiem',
        code: 'tin-tieu-diem',
        name: 'Tin Tiêu Điểm'
    },
    LATEST: {
        link: 'tinMoiNhat',
        code: 'tin-moi-nhat',
        name: 'Tin Mới Nhất'
    },
    DIOCESE: {
        code: 'tin-giao-phan',
        name: 'Tin Giáo Phận'
    },
    UNIVERSAL: {
        code: 'tin-giao-hoi-hoan-vu',
        name: 'Tin Giáo Hội Hoàn Vũ'
    },
    MOST_VIEW: {
        link: 'tinXemNhieu',
        code: 'tin-xem-nhieu',
        name: 'Bài Xem Nhiều'
    },
    NEWS_POSTS: {
        link: 'tinMoiNhat',
        code: 'bai-moi',
        name: 'Bài Mới'
    },
    CHURCH_VN: {
        code: 'tin-giao-hoi-viet-nam',
        name: 'Tin Giáo Hội Việt Nam'
    },
    SALT_FOR_LIFE : {
        code: 'muoi-men-cho-doi',
        name: 'Muối Men Cho Đời'
    },
    PRIESTLY_VOCATION: {
        code: 'on-goi-linh-muc',
        name: 'Ơn Gọi Linh Mục'
    },
    NOTIFICATIONS: {
        code: 'thong-bao',
        name: 'Thông Báo'
    },
    DOCUMENT_LIST: {
        code: 'van-kien',
        name: 'Văn Kiện'
    },
    MATERIAL_LIST: {
        code: 'tai-lieu',
        name: 'Tài Liệu'
    },
    PARABLE_LIST: {
        code: 'tu-ngu-kinh-thanh',
        name: 'Từ Ngữ Kinh Thánh'
    }
}

export const HOMEPAGE_DATA_TYPE = {
    NEWS: {
        code: 'news-list',
        name: ''
    },
    DOSSIL: {
        link: 'van-kien',
        code: 'document-list',
        name: 'Văn Kiện'
    },
    PARABLES: {
        link: 'tu-ngu-kinh-thanh',
        code: 'parable-list',
        name: 'Từ Ngữ Kinh Thánh'
    },
    NOTIFICATIONS: {
        link: 'thong-bao',
        code: 'notification-list',
        name: 'Thông Báo'
    }
}
