import moment from 'moment';
import HomeService from "@/services/api-service";
import WorkspaceSettings from "@/configuration/workspace-settings";
import {getDioceses} from "@/services/home-service";
import {GlobalSettings} from "@/configuration/global-settings";

const prod = 'production' === process.env.NODE_ENV;

class SharedService {
    constructor(workingSpaceSettings, dioceses, categories, widgets) {
        this.workingSpaceSettings = new WorkspaceSettings(workingSpaceSettings);
        this.dioceses = dioceses;
        this.categories = categories;
        this.widgets = widgets;
    }

    moment() {
        return moment();
    }

    isNullOrEmpty(data) {
        return data === null || data === "" || data === undefined;
    }

    isValidUrl(string) {
        try {
            new URL(string);
            return true;
        } catch (err) {
            return false;
        }
    }

    isChangedValue(orginalValue, currentValue) {
        let orginal = (orginalValue === null || orginalValue === undefined) ? '' : orginalValue;
        let current = (currentValue === null || currentValue === undefined) ? '' : currentValue;
        return orginal != current;
    }

    isString(value) {
        return typeof value === 'string' || value instanceof String;
    };

    convertDateStringToMomentUTC_0(data) {
        if (!this.isNullOrEmpty(data)) {
            return this.moment.utc(data);
        }
        return null;
    }

    getWeekDay(key) {
        switch (key) {
            case 'Monday':
                return 'Thứ Hai';
            case 'Tuesday':
                return 'Thứ Ba';
            case 'Wednesday':
                return 'Thứ Tư';
            case 'Thursday':
                return 'Thứ Năm';
            case 'Friday':
                return 'Thứ Sáu';
            case 'Saturday':
                return 'Thứ Bảy';
            case 'Sunday':
                return 'Chúa Nhật';
        }
    }


    getClergyLevel(item) {
        if (!this.isNullOrEmpty(item.level)) {
            let level = this.getValueAutocomplete(item.level, this.levelList, 'code');
            if (level && level.name) {
                return level.name;
            }
        }
        return "";
    }

    updateLevelDioceses(type) {
        switch (type) {
            case 'dioceses':
            case 'giao_phan':
                return 'Giáo phận'
            case 'tong_giao_phan':
                return 'Tổng giáo phận'
            case 'ecclesiastical-province':
                return 'Giáo tỉnh'
            default:
                return "";
        }
    }

    getTypeGetData(entityType) {
        switch (entityType) {
            case 'giao_xu':
            case 'giao_diem':
            case 'giao_ho':
                return 'organization';
            default:
                return 'group';
        }
    }

    getOrderOrg(entityType) {
        switch (entityType) {
            case 'giao_xu':
                return "1"
            case 'giao_diem':
                return "2"
            case 'giao_ho':
                return '3';
            default:
                return '4';
        }
    }

    getOrderPositionClergy(position) {
        switch (position) {
            case 'chanh_xu':
                return '1';
            case 'pho_xu':
                return '2';
            case 'pho_biet_cu':
                return '3';
            case 'huu':
            case 'nghi_duong':
            case 'huu_duong':
                return '9';
            default:
                return "5";
        }
    }

    getClergyStatus(status) {
        switch (status) {
            case 'duong_nhiem':
                return 'Đương Nhiệm'
            case 'man_nhiem':
                return 'Mãn Nhiệm'
            case 'cho_thuyen_chuyen':
                return 'Chờ Thuyên Chuyển'
            case 'cho_xac_nhan':
                return 'Chờ Xác Nhận'
            case 'dang_phuc_vu':
                return 'Đang Phục Vụ'
            case 'huu':
            case 'huu_duong':
                return 'Hưu Dưỡng'
            case 'rip':
                return 'R.I.P'
            default:
                return "Chưa Xác Định";
        }
    }

    getClergyStatusClass(status) {
        switch (status) {
            case 'duong_nhiem':
            case 'dang_phuc_vu':
                return 'approved-label'
            case 'man_nhiem':
            case 'huu':
            case 'huu_duong':
                return 'end-label'
            case 'cho_thuyen_chuyen':
                return 'pending-label pending-label--appointment'
            case 'cho_xac_nhan':
                return 'draft-label'
            case 'rip':
                return 'rip-label'
            default:
                return "draft-label";
        }
    }

    getIdentityCardTypeName(identityCardType) {
        switch (identityCardType) {
            case 'chung_minh':
                return 'Chứng Minh'
            case 'can_cuoc':
                return 'Căn Cước'
            case 'passport':
                return 'Passport'
            default:
                return identityCardType;
        }
    }

    updateTypeOrg(type) {
        switch (type) {
            case 'giao_hat':
                return 'Giáo hạt'
            case 'giao_xu':
                return 'Giáo xứ'
            case 'giao_ho':
                return 'Giáo họ'
            case 'giao_diem':
                return 'Giáo điểm'
            case 'dong_tu':
                return 'Dòng'
            case 'cong_doan':
                return 'Cộng Đoàn'
            case 'co_so_giao_phan':
                return 'Cơ Sở Giáo Phận';
            case 'co_so_ngoai_giao_phan':
                return 'Cơ Sở Ngoài Giáo Phận';
            case 'ban_muc_vu':
                return 'Ban Mục Vụ';
            case 'ban_chuyen_mon':
                return 'Ban Chuyên Môn';
            default:
                return "";
        }
    }

    updateNameTypeOrg(type) {
        switch (type) {
            case 'giao_hat':
                return 'Giáo hạt'
            case 'giao_xu':
            case 'giao_xu_ngoai_giao_phan':
                return 'Giáo xứ'
            case 'giao_diem':
                return 'Giáo điểm'
            case 'giao_ho':
                return 'Giáo họ'
            case 'dong_tu':
                return 'Dòng'
            case 'cong_doan':
                return 'Cộng Đoàn'
            case 'dioceses':
            case 'diocese':
                return 'Giáo Phận'
            // case 'co_so_giao_phan':
            //     return 'Cơ Sở Giáo Phận';
            // case 'ban_muc_vu':
            //     return 'Ban Mục Vụ';
            // case 'ban_chuyen_mon':
            //     return 'Ban Chuyên Môn';
            default:
                return "";
        }
    }

    parseOffsetTimezone(offset) {
        if (offset) {
            if (typeof offset === "number") {
                return offset;
            }
            ;
            let arrOffset = offset.split(".");
            if (arrOffset.length > 1) {
                return (
                    parseFloat(arrOffset[0] || 0) +
                    parseFloat(arrOffset[1] || 0) / 60
                );
            }
            ;
            return parseFloat(arrOffset[0] || 0);
        }
        ;
        return 0;
    }

    convertDateStringToMoment(data, offset) {
        if (!this.isNullOrEmpty(data)) {
            if (/Z$/.test(data) === false) {
                data = data + 'Z';
            }
            ;
            let date = moment(data);
            let currentTimezone = this.parseOffsetTimezone(offset) * 60;
            let timezone = date._offset;
            let timespan = 0;
            if (currentTimezone !== timezone) {
                timespan = 0 - (currentTimezone - timezone);
            }
            if (date.isDST()) {
                date.subtract(timespan, "m");
            }
            return date;
        }
        return null;
    }

    ISOStartDay(data) {
        if (!this.isNullOrEmpty(data)) {
            return data.startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]');
        }
        ;
        return null;
    }

    ISODay(data) {
        if (!this.isNullOrEmpty(data)) {
            return data.format('YYYY-MM-DDTHH:mm:ss[Z]');
        }
        return null;
    }

    ISOEndDay(data) {
        if (!this.isNullOrEmpty(data)) {
            return data.endOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]');
        }
        ;
        return null;
    }

    formatISODate(data, pattern) {
        if (!this.isNullOrEmpty(data) && pattern) {
            return moment(data, "YYYY-MM-DDTHH:mm:ss[Z]").format(pattern);
        }
        if (!this.isNullOrEmpty(data)) {
            return moment(data, "YYYY-MM-DDTHH:mm:ss[Z]").format('DD/MM/YYYY');
        }
        return "";
    }

    formatDate(data) {
        if (!this.isNullOrEmpty(data)) {
            return data.format('DD/MM/YYYY');
        }
        return "";
    }

    formatDateTime(data) {
        if (!this.isNullOrEmpty(data)) {
            return data.format('DD/MM/YYYY hh"mm');
        }
        ;
        return "";
    }

    removeAccents(str) {
        return !this.isNullOrEmpty(str) ? str.normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/đ/g, 'd').replace(/Đ/g, 'D') : str;
    }

    handleODataSpecialCharacters(attribute) {
        let result = '';
        result = encodeURIComponent(attribute);
        return result;
    }

    checkValueExistsInArray(value, data) {
        if (!this.isNullOrEmpty(value)) {
            if (data && data.length > 0) {
                for (let item of data) {
                    if (item === value) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    checkItemExistsInArray(value, data, key = 'id') {
        if (!this.isNullOrEmpty(value)) {
            if (data && data.length > 0) {
                for (let item of data) {
                    if (item[key] === value) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    getNameExistsInArray(value, data, key = 'id', keyTitle = 'name') {
        if (!this.isNullOrEmpty(value)) {
            if (data && data.length > 0) {
                for (let item of data) {
                    if (item[key] === value) {
                        return item[keyTitle];
                    }
                }
            }
        }
        return "";
    }

    getOptimizeImage(url, type) {
        let regex = /[\/.](gif|jpg|jpeg|tiff|png|webp)$/;
        let convertUrl = "";
        if(url) {
            switch (type) {
                case 'small-post-view':
                case 'main-post-view':
                    convertUrl = `${url}?size=sm`;
                    break;
                case 'hd':
                    convertUrl = `${url}?size=hd`;
                    break;
                case 'top-slider':
                    convertUrl = url.replace('?size=sm', '');
                    break;
                default:
                    convertUrl = url.replace('?size=sm', '');
            }
            // convertUrl = convertUrl.replace('http://api.client', 'https://apis.client');
        }
        return convertUrl;
    }

    getItemExistsInArray(value, data, key = 'id') {
        if (!this.isNullOrEmpty(value)) {
            if (data && data.length > 0) {
                for (let item of data) {
                    if (item[key] === value) {
                        return item;
                    }
                }
            }
        }
        return null;
    }

    convertLocaleLowerCase(str) {
        if (!this.isNullOrEmpty(str)) {
            return str.toLocaleLowerCase();
        }
        return str;
    }

    convertUpperCase(str) {
        if (!this.isNullOrEmpty(str)) {
            return str.toUpperCase();
        }
        return str;
    }

    removeVietnameseTones(str) {
        if (!this.isNullOrEmpty(str)) {
            str = str.normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .replace(/đ/g, "d")
                .replace(/Đ/g, "D");
        }
        return str;
    }

    firstUpperCase(str) {
        if (!this.isNullOrEmpty(str)) {
            const arr = this.convertLocaleLowerCase(str).split(" ");
            return arr.map(it => {
                return it.charAt(0).toUpperCase() + it.slice(1);
            }).join(" ");
        }
        return str;
    }

    getLinkOfName(str, key = '-') {
        if (!this.isNullOrEmpty(str)) {
            str = this.removeVietnameseTones(str);
            const validatePattern = /[\&\*\(\)\{\}\[\]\|\<\>\?\/\\\'\`\!\%\^\:\,\.\;]/g;
            str = this.convertLocaleLowerCase(str.replace(validatePattern, ''));
            str = str.replace(/ /g, key);
        }
        return str;
    }

    convertUnit(quantity) {
        const length = quantity?.toString().length;
        if (length >= 7) {
            return `${(quantity/1000000).toFixed(1)}M`
        }

        if (length >= 4) {
            return `${(quantity/1000).toFixed(1)}K`
        }

        return quantity;
    }

    getPostPath(post) {
        return `/bai-viet/${post.categoryCode}/${post.link}`;
    }
}

async function build() {
    let resp = await HomeService.getWorkspaceSettings();
    let widgets = await HomeService.getWidgets();
    let result = resp.value.reduce((result, e) => {
        result[e.code] = e.value;
        return result;
    }, {});
    const dioceses = await getDioceses({
        filter: `type eq 'dioceses' and status ne 'inactive'`,
        select: `id,name,group,level,website`
    });
    const diocesesFiltered = dioceses.value.reduce((p, c) => {
        const {group, ...otherData} = c;

        if (!(group in p)) {
            p[group] = [];
        }

        p[group].push(otherData)
        return p
    }, {})

    // Additions
    result.tags = await HomeService.getTags();
    const categories = await HomeService.getCategories();

    return new SharedService(result, diocesesFiltered, categories.value, widgets);
}

let SharedServiceInstance = Object.freeze(await build());
export default SharedServiceInstance;