
export function HandleResponse(response) {
    let result = {
        value: response?.results || [],
        pageData: {
            pageSize: response?.pageSize || 1,
            pageCount: response?.pageCount || 0,
            currentPage: response?.currentPage || 1,
        },
        total: parseInt(response?.rowCount, 10) || 0
    }

    if (response?.errorCode === 1) {
        result.errorMessage = response.message
    }

    return result;
}
