export default class WorkspaceSettings {
    constructor(props) {
        this.address = props.address;
        this.email = props.email;
        this.phone = props.phone;
        this.subtitle = props.subtitle;
        this.title = props.title;
        this.logo = props.logo.replace('public/', '');
        this.tags = props.tags;
        this.slogans = JSON.parse(props.topHeaderSlogans);
        this.icons = JSON.parse(props.topHeaderContacts);
    }

}