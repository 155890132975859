import HttpService from "@/services/http-service";
import {GlobalSettings} from "@/configuration/global-settings";
import { HandleResponse } from '@/components/helpers/response.js';
import { ENDPOINTS } from '@/components/helpers/apis.js';

function addParameter(baseUrl, parameter) {
    for (const key in parameter) {
        if (parameter.hasOwnProperty(key)) {
            baseUrl += '/' + parameter[key];
        }
    }

    return baseUrl;
}

class ApiService {

    const
    rootAPI = GlobalSettings.Settings.Server + "/api/v1";

    async getItems(url, options) {
        try {
            let items = await HttpService.getItems(url, options);
            let result = items['value'];
            let total = parseInt(items['@odata.count'], 10);
            return {
                total: total,
                value: result
            };
        } catch (error) {
            return this.handleError('getItems', error);
        }
    }

    async getNewsItems(url, options) {
        try {
            let response = await HttpService.getItems(url, options);
            response?.results.map(e => delete e.content);
            let result = {
                value: response?.results || [],
                pageData: {
                    pageSize: response?.pageSize || 1,
                    pageCount: response?.pageCount || 0,
                    currentPage: response?.currentPage || 1,
                },
                total: parseInt(response?.rowCount, 10) || 0
            }

            if (response?.errorCode === 1) {
                result.errorMessage = response.message
            }

            return result;
        } catch (error) {
            return this.handleError('getItems', error);
        }
    }

    async getNewsCustom(url, options) {
        try {
            let result = {};
            let response = await HttpService.getItems(url, options);

            if (response?.errorCode === 1) {
                result.errorMessage = response.message
            } else {
                result.cateType = 'link';
                result.value = response;
            }

            return result;
        } catch (error) {
            
        }
    }

    async getDataItems(baseUrl, options) {
        let pageOption = {}
        let restrictions = [];
        let sorts = [];
        if (options) {
            if (options.pageOption.page) {
                pageOption.page = options.pageOption.page;
            }
            if (options.pageOption.pageSize) {
                pageOption.pageSize = options.pageOption.pageSize;
            }
            if (options.restrictions) {
                restrictions = options.restrictions;
            }
            if (options.sorts) {
                sorts = options.sorts;
            }
        }
        let params = {
            "pageOption": pageOption,
            "restrictions": restrictions,
            "sorts": sorts
        }
        try {
            return await HttpService.post(baseUrl, null, params);
        } catch (error) {
            return this.handleError('getDataItems', error);
        }
    }

    async searchClergies(options) {
        const baseUrl = this.rootAPI + `/clergies/search`;
        try {
            return await this.getDataItems(baseUrl, options);
        } catch (error) {
            return this.handleError('searchClergies', error);
        }
    }

    async searchChurches(options, params) {
        let baseUrl = this.rootAPI + ENDPOINTS.CHURCHES_SEARCH;

        if (params.appointmentID) {
            baseUrl = baseUrl.replace('{key}', params.appointmentID);
        }
        try {
            let responseData = await this.getDataItems(baseUrl, options);
            return responseData;
        } catch (error) {
            return this.handleError('searchChurches', error);
        }
    }

    async searchOrganizations(options) {
        const baseUrl = this.rootAPI + `/organizations/search`;
        try {
            return await this.getDataItems(baseUrl, options);
        } catch (error) {
            return this.handleError('searchOrganizations', error);
        }
    }

    async getGroups(options) {
        let baseUrl = this.rootAPI + '/groups';
        try {
            return await this.getItems(baseUrl, options);
        } catch (error) {
            return this.handleError('getGroups', error);
        }
    }

    async getGroup(id, options) {
        let baseUrl = this.rootAPI + '/groups';
        try {
            return await HttpService.getItem(baseUrl, id, options);
        } catch (error) {
            return this.handleError('getGroup', error);
        }
    }

    async getGroupAnalytics(id, options) {
        let baseUrl = this.rootAPI + `/groups/${id}/analytics`;
        try {
            return await HttpService.getItem(baseUrl, null, options);
        } catch (error) {
            return this.handleError('getGroup', error);
        }
    }

    /**
     *
     * @param {*} paramsURL The structure: type - id - endpoint
     * @param {*} options
     * @returns
     */
    async getGroupCommon(paramsURL, options) {
        let baseUrl = this.rootAPI + '/groups';
        baseUrl = addParameter(baseUrl, paramsURL);

        try {
            return HandleResponse(await this.getDataItems(baseUrl, options));
        } catch (error) {
            return this.handleError('getGroupCommon', error);
        }
    }

    async getOrganizations(options) {
        let baseUrl = this.rootAPI + '/organizations';
        try {
            return await this.getItems(baseUrl, options);
        } catch (error) {
            return this.handleError('getOrganizations', error);
        }
    }

    async getOrganization(id, options) {
        let baseUrl = this.rootAPI + '/organizations';
        try {
            return await HttpService.getItem(baseUrl, id, options);
        } catch (error) {
            return this.handleError('getOrganization', error);
        }
    }

    async getSaints(options) {
        let baseUrl = this.rootAPI + '/saints';
        try {
            return await this.getItems(baseUrl, options);
        } catch (error) {
            return this.handleError('getSaints', error);
        }
    }

    async getParablesDaily(options) {
        let baseUrl = this.rootAPI + '/daily-parables';
        try {
            return await this.getItems(baseUrl, options);
        } catch (error) {
            return this.handleError('getParablesDaily', error);
        }
    }

    async getPositions(options) {
        let baseUrl = this.rootAPI + '/positions';
        try {
            return await this.getItems(baseUrl, options);
        } catch (error) {
            return this.handleError('getPositions', error);
        }
    }

    async getAnniversaries(options) {
        let baseUrl = this.rootAPI + '/anniversaries';
        try {
            return await this.getItems(baseUrl, options);
        } catch (error) {
            return this.handleError('getAnniversaries', error);
        }
    }

    async getAppointments(options) {
        let baseUrl = this.rootAPI + '/appointments';
        try {
            return await this.getItems(baseUrl, options);
        } catch (error) {
            return this.handleError('getAppointments', error);
        }
    }

    async getClergies(options) {
        let baseUrl = this.rootAPI + '/clergies';
        try {
            return await HttpService.getItems(baseUrl, options);
        } catch (error) {
            return this.handleError('getClergy', error);
        }
    }

    async getClergy(id, options) {
        let baseUrl = this.rootAPI + '/clergies';
        try {
            return await HttpService.getItem(baseUrl, id, options);
        } catch (error) {
            return this.handleError('getClergy', error);
        }
    }

    async getDioceses(options) {
        let baseUrl = this.rootAPI + '/dioceses';
        try {
            return await this.getItems(baseUrl, options);
        } catch (error) {
            return this.handleError('getDioceses', error);
        }
    }

// Masses
    async getMasses(options) {
        let baseUrl = this.rootAPI + '/masses';
        try {
            return await this.getItems(baseUrl, options);
        } catch (error) {
            return this.handleError('getMasses', error);
        }
    }

//Authors
    async getAuthors(options) {
        let baseUrl = this.rootAPI + '/authors';
        try {
            return await this.getItems(baseUrl, options);
        } catch (error) {
            return this.handleError('getAuthors', error);
        }
    }

    async getAuthor(id, options) {
        let baseUrl = this.rootAPI + '/authors';
        try {
            return await HttpService.getItem(baseUrl, id, options);
        } catch (error) {
            return this.handleError('getAuthor', error);
        }
    }

//Posts
    async getPosts(options) {
        let baseUrl = this.rootAPI + '/posts';
        try {
            return await this.getItems(baseUrl, options);
        } catch (error) {
            return this.handleError('getPosts', error);
        }
    }

    async getPost(id, code, options) {
        let urlCommon = this.rootAPI + `/articles/`;
        let baseUrl = urlCommon + `category-codes/${code}`;

        if (options?.tagCode) {
            baseUrl = urlCommon + `tag-codes/${code}`;
        }
        if (id.length > 0) {
            baseUrl = urlCommon + `links/${decodeURIComponent(id)}`;
        }

        try {
            return await HttpService.getItem(baseUrl, '', options);
        } catch (error) {
            return this.handleError('getPost', error);
        }
    }

//contemplations
    async getContemplations(options) {
        let baseUrl = this.rootAPI + '/contemplations';
        try {
            return await this.getItems(baseUrl, options);
        } catch (error) {
            return this.handleError('getContemplations', error);
        }
    }

    async getContemplation(id, code, options) {
        let baseUrl = this.rootAPI + '/contemplations';
        try {
            return await HttpService.getItem(baseUrl, id, options);
        } catch (error) {
            return this.handleError('getContemplation', error);
        }
    }

    async getContemplationCategory(options) {
        const baseUrl = this.rootAPI + '/contemplations/category-type';
        try {
            const itemCategory = await HttpService.getItems(baseUrl, options);
            let result = {
                value: itemCategory?.results,
                pageData: {
                    pageSize: itemCategory?.pageSize || 1,
                    pageCount: itemCategory?.pageCount,
                    currentPage: itemCategory?.currentPage || 1,
                },
                total: parseInt(itemCategory?.rowCount, 10) || 0
            }

            return result;
        } catch (error) {
            return this.handleError('getPostsCategory', error);
        }
    }

//Parables
    async getParables(options) {
        let baseUrl = this.rootAPI + '/parables';
        try {
            return await this.getItems(baseUrl, options);
        } catch (error) {
            return this.handleError('getParables', error);
        }
    }

    async getParable(id, options) {
        let baseUrl = this.rootAPI + '/parables';
        try {
            return await HttpService.getItem(baseUrl, id, options);
        } catch (error) {
            return this.handleError('getParable', error);
        }
    }

//Categories
    async getCategories(options) {
        let baseUrl = this.rootAPI + '/categories';
        try {
            return await this.getItems(baseUrl, options);
        } catch (error) {
            return this.handleError('getCategories', error);
        }
    }

    async getCategory(options) {
        let baseUrl = this.rootAPI + '/categories';
        try {
            return await HttpService.getItem(baseUrl, id, options);
        } catch (error) {
            return this.handleError('getCategory', error);
        }
    }

    async getMainMenus(endpoint, options) {
        let baseUrl = this.rootAPI + '/main-menus';
        baseUrl = endpoint ? baseUrl += '/' + endpoint : '';

        try {
            if (options?.cateType === 'link') {
                return await this.getNewsCustom(baseUrl);
            }
            return await this.getNewsItems(baseUrl, options);
        } catch (error) {
            return this.handleError('getCategories', error);
        }
    }

// WorkspaceSettings
    async getWorkspaceSettings(options) {
        let baseUrl = this.rootAPI + '/workspace-settings';
        try {
            return await this.getItems(baseUrl, options);
        } catch (error) {
            return this.handleError('getWorkspaceSettings', error);
        }
    }

    async getWorkspaceSetting(id, options) {
        let baseUrl = this.rootAPI + '/workspace-settings';
        try {
            return await HttpService.getItem(baseUrl, id, options);
        } catch (error) {
            return this.handleError('getWorkspaceSetting', error);
        }
    }

    //Feast day
    async getFeastDay(options) {
        let baseUrl = this.rootAPI + '/ngay-le';
        try {
            return await this.getItems(baseUrl, options);
        } catch (error) {
            return this.handleError('ngay-le', error);
        }
    }

    //Get calendar by date (YYYY-MM-DD)
    async getCatholic(date) {
        let baseUrl = this.rootAPI + `/calendars/${date}/catholic`;
        try {
            return await this.getItems(baseUrl);
        } catch (error) {
            return this.handleError('ngay-le', error);
        }
    }

    async getGospel(code, options) {
        let baseUrl = this.rootAPI + `/kinh-thanh/gospels/${code}`;
        try {
            let result = await HttpService.getItems(baseUrl, options);
            return result.data;
        } catch (error) {
            return this.handleError('kinh-thanh', error);
        }
    }

    async getSameCategoryNews(type, options) {
        let baseUrl = this.rootAPI + '/articles/category-codes/' + type;
        try {
            return await this.getNewsItems(baseUrl, options);
        } catch (error) {
            return this.handleError('getNewsItems', error);
        }
    }

    async getHighlightNews(id, options) {
        let baseUrl = this.rootAPI + `/articles/${id}/surrounding`;
        try {
            return await HttpService.getItem(baseUrl, options);
        } catch (error) {
            return this.handleError('getHighlightNews', error);
        }
    }

    async getSameAuthorNews(id, options) {
        let baseUrl = this.rootAPI + '/articles/authors/' + id;
        try {
            return await this.getNewsItems(baseUrl, options);
        } catch (error) {
            return this.handleError('getNewsItems', error);
        }
    }

    async getCategoryLatestNews(type, options) {
        let baseUrl = this.rootAPI + `/articles/category-codes/${type}/latest`;
        try {
            return await this.getNewsItems(baseUrl, options);
        } catch (error) {
            return this.handleError('getNewsItems', error);
        }
    }

    async getWidgets(options) {
        let baseUrl = this.rootAPI + '/widgets';
        try {
            return await this.getItems(baseUrl, options);
        } catch (error) {
            return this.handleError("get slider banner", error);
        }
    }

    handleError(methodName, errorData) {
        let errorResponse = {
            status: errorData.status,
            message: errorData.message
        };
        return new Error(errorResponse.message);
    }

    // Audios
    async getAudio(id, code, options) {
        let baseUrl = this.rootAPI + `/audio/category-codes/${code}`;
        try {
            return HandleResponse(await HttpService.getItem(baseUrl, id, options));
        } catch (error) {
            return this.handleError('getPost', error);
        }
    }

    async getAudioCategories(options) {
        let baseUrl = this.rootAPI + `/audio/categories`;
        try {
            return HandleResponse(await HttpService.getItem(baseUrl, options));
        } catch (error) {
            return this.handleError('getAudioCategory', error);
        }
    }

    async increaseView(audioId) {
        let baseUrl = this.rootAPI + `/audio/episodes/${audioId}/increase-views`;
        try {
            let resp = await fetch(baseUrl, { method: "POST" });
        } catch (error) {
            return this.handleError("increaseView", error)
        }
    }    

    // Tags
    async getTags(options) {
        let baseUrl = this.rootAPI + `/tags`;
        try {
            return await HttpService.getItems(baseUrl, options);
        } catch (error) {
            return this.handleError('getPost', error);
        }
    }

    //Color
    async getColor(options) {
        let baseUrl = this.rootAPI + `/setting-colors/default`;
        try {
            let resp = await fetch(baseUrl);
            return resp.json();
        } catch (error) {
            return this.handleError('getPost', error);
        }
    }

    async getRandomNew(options) {
        let baseUrl = this.rootAPI + `/articles/random-list`;
        try {
            let resp = await this.getNewsItems(baseUrl);
            return resp.value[0];
        } catch (error) {
            return this.handleError('getPost', error);
        }
    }

    async getCatalogues(id, options) {
        let baseUrl = this.rootAPI + `/articles/catalogues`;
        try {
            return HandleResponse(await HttpService.getItem(baseUrl, id, options));
        } catch (error) {
            return this.handleError('getCatalogues', error);
        }
    }

    async getAllAudio() {
        let baseUrl = this.rootAPI + `/audio/episodes`;
        try {
            let resp = await fetch(baseUrl);
            return resp.json();
        } catch (error) {
            return this.handleError('getAudio', error);
        }
    }

    async getNewsByParableCode(code, params) {
        let baseUrl = this.rootAPI + `/articles/search`;
        params = {
            ...params, restrictions: [
                {
                    "key": "parableCode",
                    "value": code
                }
            ],
            pageOption: {
                "page": 1,
                "pageSize": 10
            },
            sorts: [
                {
                    "key": "publishDate",
                    "sortType": "DESC"
                }
            ]
        };
        try {
            return await this.getDataItems(baseUrl, params);
        } catch (error) {
            return this.handleError('getNewsByParableCode', error);
        }
    }

    async getSearchNews(query, params) {
        let baseUrl = this.rootAPI + `/articles/search`;
        params = {
            ...params, restrictions: [
                {
                    "key": "title",
                    "value": query
                },
                {
                    "key": "metaDescription",
                    "value": query
                },
            ],
            sorts: [
                {
                    "key": "publishDate",
                    "sortType": "DESC"
                }
            ]
        };
        try {
            return HandleResponse(await this.getDataItems(baseUrl, params));
        } catch (error) {
            return this.handleError('getNewsByParableCode', error);
        }
    }

    // Members
    async getAllMembers() {
        let baseUrl = this.rootAPI + `/members`;

        try {
            let resp = await fetch(baseUrl);
            return resp.json();
        } catch (error) {
            return this.handleError('getAllMembers', error);
        }
    }
}

export default new ApiService();