import ApiService from "@/services/api-service";
import {HandleResponse} from '@/components/helpers/response.js';

import {TYPE_SPECIAL_GROUP} from '@/components/helpers/constant.js';
import {GlobalSettings} from "@/configuration/global-settings";

var _ = require('lodash');

export async function getDailyEvents(date) {
    let dateStr = date.format("YYYY-MM-DD");
    let dailyEvents = await ApiService.getCatholic(dateStr);
    let rips = dailyEvents.value.anniversaries.filter(e => e.type === 'rip');
    let births = dailyEvents.value.anniversaries.filter(e => e.type === 'birth');
    let priests = dailyEvents.value.anniversaries.filter(e => e.type === 'linh_muc');
    let saints = dailyEvents.value.anniversaries.filter(e => e.type === 'saint');
    return {
        rip: rips,
        birth: births,
        priest: priests,
        saint: saints,
        scheduleEvents: dailyEvents.value.scheduleEvents,
        masses: dailyEvents.value.masses
    }
}

export async function getLogos(number, fields) {
    let options = {};
    if (!number) {
        options['top'] = number;
    }
    if (!fields) {
        options['select'] = fields;
    }
    return await ApiService.getContemplations(options);
}

/**
 * The common function to get the special group
 * @param {Object} params
 * Mandatory: type - defining what the group will the filter
 * Optional: sort - Object with <attribute> that is the name to process
 * @returns {Object}
 */
export async function getGroup(params) {
    let options = {};

    switch (params.type) {
        case TYPE_SPECIAL_GROUP.DEANERY:
            options.filter = "type eq 'giao_hat'"
            break;

        default:
            break;
    }

    if (params.sort) {
        options.sort = params.sort.attribute;
        options.sort += ' ' + (params.sort.order ? params.sort.order : 'asc');
    }
    let results = await ApiService.getGroups(options);
    results.value.map(e => delete e.content);
    return results;
}

export async function getGospel(code) {
    let options = {};
    return await ApiService.getGospel(code, options);
}

export async function getDioceses(options) {
    return await ApiService.getDioceses(options);
}

export async function getCategoriesSettings(options) {
    return await ApiService.getCategories(options);
}

export async function getPostDetail(id, code, options) {
    let url =  `${GlobalSettings.Settings.WebServer}/api/posts?post-id=${id}&code=${code}`;
    let result = await fetch(url);
    return result.json();
}

export async function getSameCategoryNews(type, options) {
    let result = {};
    let response = await ApiService.getSameCategoryNews(type, options);

    result.total = response.total;
    result.value = response.value;

    return result;
}

export async function getHighlightNews(id, options) {
    let result = {};
    let response = await ApiService.getHighlightNews(id, options);

    result.previousNew = response.previousArticle;
    result.currentNew = response.currentArticle;
    result.nextNew = response.nextArticle;

    return result;
}

export async function getCategoryLatestNews(type, options) {
    let result = {};
    let response = await ApiService.getCategoryLatestNews(type, options);

    result.total = response.total;
    result.value = response.value;

    return result;
}

export async function getCategories(url) {
    let result = await fetch(url);
    return result.json();
}

export async function getCategoryData(cateCode, options = {}) {
    let result = HandleResponse(await ApiService.getPost('', cateCode, options));
    result.value = result.value.map((e) => {
        return {
            id: e.id,
            title: e.title,
            photo: e.photo,
            link: e.link,
            mediaFileEmbedId: e.mediaFileEmbedId || '',
            metaDescription: e.metaDescription,
            visit: e.visit,
            hotNewsPhoto: e.hotNewsPhoto,
            created: e.publishDate,
            creatorName: e.creatorName,
            authorName: e.authorName,
            categories: e.categories,
            categoryCode: e.categoryCode ? e.categoryCode : ''
        };
    });
    return result;
}

export function getSliderBanner(widgets) {
    const banners = widgets.find(item => item.code === 'slide-banner');
    return banners ? JSON.parse(banners.data) : [];
}

export function getBanners(widgets) {
    const banners = widgets.filter(item => item.type === 'hinh-anh-gan-link');
    return banners.map(b => JSON.parse(b.data));
}

export async function getWidgets(options = {}) {
    let result = await ApiService.getWidgets(options);
    return result.value;
}

export async function getColor(options = {}) {
    return await ApiService.getColor(options);
}

export async function getRandomNews(options = {}) {
    return await ApiService.getRandomNew(options);
}

export async function getCatalogues(id) {
    return await  ApiService.getCatalogues(id);
}

export async function getAuthorNews(id, options = {}) {
    return await ApiService.getSameAuthorNews(id, options);
}

export async function getAudioCategory() {
    let result = await ApiService.getAllAudio();
    return result.results;
}

export async function getNewsByParableCode(code) {
    return await ApiService.getNewsByParableCode(code, {});
}

export async function getSearchNews(query, page) {
    return await ApiService.getSearchNews(query, {
        pageOption: {
            "page": page,
            "pageSize": 10
        },
    });
}