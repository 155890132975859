import '@/styles/globals.css';
import Layout from '@/components/layout';
import SharedServiceInstance from "@/services/shared-service";
import ScrollToTopButton from "@/components/shared/scroll-to-top-button";
import {Fragment, useEffect} from "react";
import {applyTheme, createTheme} from "@/styles/themes/utils";
import {getColor, getRandomNews} from "@/services/home-service";
import {toast, ToastContainer} from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Image from "next/image";
import Link from "next/link";
import SharedService from "@/services/shared-service";
import Script from "next/script";

export default function App({Component, pageProps, ...appProps}) {
    const isLayoutNeeded = !appProps.router.pathname.startsWith(`/posts/in`) && !appProps.router.pathname.startsWith(`/release`) && !appProps.router.pathname.startsWith(`/maintenance`);
    const LayoutComponent = isLayoutNeeded ? Layout : Fragment;
    useEffect(() => {
        getColor().then((result) => {
                applyTheme(createTheme({
                    primary: result['primary'],
                    secondary: result['secondary'],
                    textBase: result['textColor'],
                    custom: result['custom']
                }));
            }
        );
        if(isLayoutNeeded) {
            setInterval(() => {
                getRandomNews().then((article) => {
                    toast.info(<Link
                        href={SharedService.getPostPath(article)}
                        className="flex items-center gap-5 z-[2000]">
                        <Image src={SharedServiceInstance.getOptimizeImage(article.photo, 'small-post-view')} alt="random-news"
                               width={100} height={50}/>
                        <div>
                            <span className="font-bold">Bài viết ngẫu nhiên</span>
                            <span className="line-clamp-2">{article.metaDescription}</span>
                        </div>
                    </Link>, {
                        icon: false,
                        autoClose: 10000,
                        closeButton: true,
                        position: "bottom-right",
                        toastId: 'random-1',
                    });
                });
            }, 60000);
        }
    }, [isLayoutNeeded]);

    return (
        <>
            <Script src="https://cdn.botpress.cloud/webchat/v1/inject.js" onLoad={() => {
                const botConfig = {
                    "composerPlaceholder": "Chat với C Mate Bot",
                    "botConversationDescription": "C Mate Bot là Chat Bot của Giáo Phận Phú Cường",
                    "botId": "3227aed5-39e9-4b0b-9c78-0e3aae4e88ab",
                    "hostUrl": "https://cdn.botpress.cloud/webchat/v1",
                    "messagingUrl": "https://messaging.botpress.cloud",
                    "clientId": "3227aed5-39e9-4b0b-9c78-0e3aae4e88ab",
                    "webhookId": "7d01ee5d-4f1c-41e5-a6a5-5f38d1dde21b",
                    "lazySocket": true,
                    "themeName": "prism",
                    "botName": "C Mate Bot",
                    "enablePersistHistory": false,
                    "useSessionStorage": true,
                    "avatarUrl": "https://cmate.vn/assets/img/cmate/ic_cmate_foot_logo.png",
                    "phoneNumber": "0707307788",
                    "website": "https://giaophanphucuong.org",
                    "stylesheet": "https://webchat-styler-css.botpress.app/prod/code/a6daaced-6aa1-4d27-9b15-98b52fa2132f/v38022/style.css",
                    "frontendVersion": "v1",
                    "theme": "prism",
                    "themeColor": "#2563eb",
                    "allowedOrigins": []
                };
                window.botpressWebChat.init(botConfig);
                window.botpressWebChat.onEvent(
                    function () {
                        window.botpressWebChat.sendEvent({ type: "hide" });
                    },
                    ["LIFECYCLE.LOADED"]
                );
            }}></Script>
            <LayoutComponent sharedService={SharedServiceInstance}>
                <ScrollToTopButton/>
                <Component {...pageProps} />
            </LayoutComponent>
            <ToastContainer
                className="mb-10 z-[20000!important] md:w-[30vw!important]"
                bodyClassName={() => "text-primary"}
            />
        </>
    )
}
